import "./Component/FrontEnd/css/style.css";
import "./Component/Admin/style/Adminstyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomeRoute from "./Component/FrontEnd/PageRoute/HomeRoute";
import AboutRoute from "./Component/FrontEnd/PageRoute/AboutRoute";
import Talent from "./Component/FrontEnd/TalentList/Talent";
import Current from "./Component/FrontEnd/Cureent opening/Current";
import RpaRoute from "./Component/FrontEnd/PageRoute/RpaRoute";
import Migrat from "./Component/FrontEnd/RPA/inner/Migrat";
import Maincontact from "./Component/FrontEnd/Home/Maincontact";
import Login from "./Component/FrontEnd/Login-forget/Login";
import Adminpage from "./Component/Admin/Adminpage";
import Homepage from "./Component/Admin/Contentpages/Pages/Homepage";
import Focuscontact from "./Component/FrontEnd/RPA/Focuscontact";
import Dashboard from "./Component/Admin/Contentpages/Dashboard/Dashboard";
import Talentpage from "./Component/Admin/Contentpages/Talentlist/Talent-page";
import Profile from "./Component/Admin/Contentpages/Profile/Profile";
// import Setting from "./Component/Admin/Contentpages/Profile/Setting";
import Uploadfile from "./Component/Admin/Contentpages/Talentlist/uploadfile";
import MtsRoute from "./Component/FrontEnd/PageRoute/MtsRoute";
import Layout from "./Component/FrontEnd/Layout";
import AgiletransformationRoute from "./Component/FrontEnd/PageRoute/AgiletransformationRoute";
import ProtectedRoute from "./Component/FrontEnd/Login-forget/Protected";
import Forget from "./Component/FrontEnd/Login-forget/Forget";
import ProductengineerRoute from "./Component/FrontEnd/PageRoute/ProductengineerRoute";
import CyberRoute from "./Component/FrontEnd/PageRoute/CyberRoute";
import ItstaffRoute from "./Component/FrontEnd/PageRoute/ItstaffRoute";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <Layout />
        </>
      ),
      children: [
        {
          path: "",
          element: <HomeRoute />,
        },
        {
          path: "/about_us",
          element: <AboutRoute />,
        },
        {
          path: "/talent",
          element: <Talent />,
        },
        {
          path: "/current",
          element: <Current />,
        },
        {
          path: "/robotic_process_automation",
          element: <RpaRoute />,
        },
        {
          path: "/managed_testing_services",
          element: <MtsRoute />,
        },
        {
          path: "/migrat",
          element: <Migrat />,
        },
        {
          path: "/contact",
          element: <Maincontact />,
        },
        {
          path: "/Agile_Transformation",
          element: <AgiletransformationRoute />,
        },
        {
          path: "/Product_re_engineering",
          element: <ProductengineerRoute />,
        },
        {
          path: "/cyber-security",
          element: <CyberRoute />,
        },
        {
          path: "/It-staffing",
          element: <ItstaffRoute />,
        },
        {
          path: "/focuscontact",
          element: <Focuscontact />,
        },
      ],
    },
    {
      path: "/admin",
      element: (
        <>
          <ProtectedRoute>
            <Adminpage />
          </ProtectedRoute>
        </>
      ),
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "Profile",
          element: <Profile />,
        },
        // {
        //   path: "setting",
        //   element: <Setting />,
        // },
        {
          path: "pages/:id",
          element: <Homepage />,
        },
        {
          path: "telent",
          element: <Talentpage />,
        },
        {
          path: "talent/uploadfile",
          element: <Uploadfile />,
        },
      ],
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/forget",
      element: <Forget />,
    },
    // {
    //   path: "/resetPass/:token",
    //   element: <ResetPassword />,
    // },
  ]);
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;

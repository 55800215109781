import React from 'react'

function Rpabanner() {
    return (
        <>
            <section className='rpabanner'>
            </section>
        </>
    )
}

export default Rpabanner;
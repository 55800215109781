import React from "react";
import auto from "../../images/automationanywhere.png";
import blueprism from "../../images/blueprism.png";
import uipath from "../../images/Uipath.png";
import micro from "../../images/Microsoft-Power-Platform-min.png";

function Rpatechnologi() {
  return (
    <>
      <section className="rpatech">
        <div className="container   sulutionimg">
          <h2>We support the following RPA technologies</h2>
          <div className="imgroup">
            <div className="row">
              <div className="col-12 col-lg-4 ">
                <div className="cardbox">
                  <img src={auto} loading="lazy" alt="Automation Anywhere" />
                  <div className="solutxt">
                    <p>Automation Anywhere</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="cardbox">
                  <img src={uipath} loading="lazy" alt="blueprism" />
                  <div className="solutxt">
                    <p>UI Path</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="cardbox">
                  <img src={blueprism} loading="lazy" alt="BluePrism" />
                  <div className="solutxt">
                    <p>BluePrism</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="cardbox">
                  <img src={micro} loading="lazy" alt="Microsoft PowerAPPs" />
                  <div className="solutxt">
                    <p>Microsoft PowerAPPs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Rpatechnologi;

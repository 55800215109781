import React from "react";
import Navbar from "../Home/Navbar";
import rpa from '../../images/rpa-concept-with-hands-holding-tablet 1.png'
import qa from '../../images/standard-quality-control-collage-concept 1.png'
import sm from '../../images/still-life-business-roles-with-various-mechanism-pieces 1 (1).png'
import sa from '../../images/Mask group - 2023-12-07T111407.175-min.png'
import Talentdata from "./Talentdata";

function Talent() {
  return (
    <>
      <section className="talentbanner">
        <Navbar />
        <div className="talentm h-100">
          <div className="talenthead">
            <h1>Talents are ready to Start</h1>
          </div>
        </div>
      </section>
      <section className="services">
        <div className="container serimg">
          <div className="sertech">
            <h2>Services & Technologies</h2>
          </div>
          <div className="row textimg">
            <div className="col-12 col-lg-3">
              <div className="imgtxt">
                <img src={rpa} alt="rpa" />
                <h3>Robotic Process Automation</h3>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="imgtxt">
                <img src={qa} alt="qa" />
                <h3>QA Automation</h3>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="imgtxt">
                <img src={sm} alt="sm" />
                <h3>Scrum Master</h3>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="imgtxt">
                <img src={sa} alt="sa" />
                <h3>Staff Augmentation</h3>
              </div>
            </div>
          </div>
          <Talentdata />
          <p className="talent-copy">
            © 2024 Agile Tech Labs. All Rights Reserved.
          </p>
        </div>
      </section>
      {/* </ReactPageScroller> */}
    </>
  );
}

export default Talent;

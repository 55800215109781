import React from "react";
import cost from "../../images/fasterdelivery.png";
import improve from "../../images/improvedquality.png";
import enhanced from "../../images/flexbility.png";
import product from "../../images/colobration.png";
import scaleblity from "../../images/customersatisfaction.png";
import compliance from "../../images/riskmanagment.png";
import satisfaction from "../../images/employeemanagment.png";

function Transformbenifit() {
  return (
    <>
      <section className="mtsbenifit pb-5">
        <div className="container">
          <h2>Benefits of Agile Transformation:</h2>
          <p>
            Implementing Agile Transformation offers several benefits for a
            company:
          </p>
          <div className=" row mt-5 w-100 d-flex flex-wrap benifit-solution">
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={cost} alt="Faster Delivery" />
                <h3>Faster Delivery:</h3>
              </div>
              <p>
                By breaking work into smaller pieces and delivering them
                incrementally, products and features get to market more quickly.
              </p>
            </div>
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={improve} alt="Improved Quality" />
                <h3>Improved Quality:</h3>
              </div>
              <p>
                Regular testing and feedback help catch and fix issues early,
                leading to higher-quality results.
              </p>
            </div>
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={enhanced} alt="Flexibility and Adaptability" />
                <h3>Flexibility and Adaptability:</h3>
              </div>
              <p>
                Agile allows teams to adapt to changes and new information
                quickly, ensuring they can respond to market demands and
                evolving customer needs.
              </p>
            </div>
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={product} alt="Enhanced Collaboration" />
                <h3>Enhanced Collaboration:</h3>
              </div>
              <p>
                Agile promotes teamwork and better communication among team
                members, leading to more effective and cohesive work
              </p>
            </div>
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={scaleblity} alt="Increased Customer Satisfaction" />
                <h3>Increased Customer Satisfaction:</h3>
              </div>
              <p>
                Regularly delivering small, usable pieces of work means
                customers see continuous progress and improvements, leading to
                higher satisfaction.
              </p>
            </div>
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={compliance} alt="Better Risk Management" />
                <h3>Better Risk Management:</h3>
              </div>
              <p>
                Continuous feedback and iterative development help identify and
                address risks early in the process.
              </p>
            </div>
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={satisfaction} alt="Employee Engagement and Morale" />
                <h3>Employee Engagement and Morale:</h3>
              </div>
              <p>
                Agile practices often result in more empowered and motivated
                teams, as employees have more autonomy and a clearer
                understanding of their contributions.
              </p>
            </div>

            <p className="mt-3">
              Overall, Agile Transformation helps companies become more
              efficient, customer-focused, and resilient in a fast-changing
              business environment.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Transformbenifit;

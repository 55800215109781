import React from "react";
import img from "../../images/Rpabanner (3) 1.png";
import finance from "../../images/image 124.png";
import human from "../../images/image 125.png";
import customer from "../../images/image 126.png";
import logictics from "../../images/image 127.png";
import healthcare from "../../images/image 128.png";
import operation from "../../images/image 129.png";
import gov from '../../images/image 130.png'

function Domain() {
  return (
    <>
      <section className="postercontent mt-5 mb-5">
        <img src={img} alt="img" className="bots" />
        <div className="container">
          <div className="row w-100 bg-image">
            <h2>Domains</h2>
            <div className="poster-content mt-3">
              <h2>
                <img src={finance} className="me-2 head-icon " alt="Health" />
                Health
              </h2>
              <p>
                Managed Testing Services can help the health domain by ensuring
                that all software and systems used in healthcare are reliable,
                secure, and efficient. These services provide thorough testing
                to identify and fix any issues before they can impact patient
                care or data security. By using managed testing, healthcare
                organizations can improve the quality of their technology,
                reduce the risk of errors, and ensure compliance with health
                regulations, ultimately leading to better patient outcomes and
                smoother operations.
              </p>
            </div>
            <div className="poster-content mt-3">
              <h2>
                <img
                  src={human}
                  className="me-2 head-icon"
                  alt="Finance/Banking"
                />
                Finance/Banking
              </h2>
              <p>
                Managed Testing Services can help the finance sector by ensuring
                that all software and applications are reliable, secure, and
                perform well. These services handle all aspects of testing, from
                planning and executing tests to finding and fixing issues, which
                helps to avoid costly errors and security breaches. By
                outsourcing testing, finance companies can focus on their core
                business activities, reduce time to market for new products, and
                improve customer satisfaction with higher quality software
                solutions.
              </p>
            </div>
            <div className="poster-content mt-3">
              <h2>
                <img
                  src={customer}
                  className="me-2 head-icon"
                  alt="Automative"
                />
                Automative
              </h2>
              <p>
                Managed Testing Services can help the automotive industry by
                ensuring that software systems and applications are reliable,
                safe, and perform well. These services provide expert testers
                who use advanced tools and methods to find and fix bugs quickly.
                This improves the quality of the software, reduces the time it
                takes to release new features, and ensures compliance with
                industry standards. As a result, automotive companies can focus
                on innovation and delivering better products to their customers.
              </p>
            </div>
            <div className="poster-content mt-3">
              <h2>
                <img src={logictics} className="me-2 head-icon" alt="IT" />
                IT
              </h2>
              <p>
                Managed Testing Services help IT firms by taking care of all
                their software testing needs. This means they handle everything
                from planning and executing tests to finding and fixing bugs. By
                doing this, IT firms can save time, reduce costs, and ensure
                their software works well and meets quality standards. This
                allows the IT teams to focus more on developing new features and
                improving their products, while the managed testing service
                experts ensure everything runs smoothly.
              </p>
            </div>
            <div className="poster-content mt-3">
              <h2>
                <img
                  src={healthcare}
                  className="me-2 head-icon"
                  alt="Utility"
                />
                Utility
              </h2>
              <p>
                Managed Testing Services can help utility firms by ensuring
                their software and systems work correctly and efficiently. These
                services provide expert testing to identify and fix issues
                before they cause problems, reducing downtime and improving
                reliability. By outsourcing testing, utility firms can focus on
                their core operations, save money, and benefit from the latest
                testing tools and techniques. This leads to better customer
                service, as systems are more dependable and user-friendly
              </p>
            </div>
            <div className="poster-content mt-3">
              <h2>
                <img src={operation} className="me-2 head-icon" alt="Retail" />
                Retail
              </h2>
              <p>
                Managed Testing Services can help retail firms by ensuring their
                software and systems work smoothly and reliably. These services
                handle all aspects of testing, from finding bugs to making sure
                new features work as intended. This leads to better customer
                experiences, fewer technical issues, and more efficient
                operations. By outsourcing testing, retail firms can focus on
                their core business while experts take care of maintaining the
                quality and performance of their technology.
              </p>
            </div>
            <div className="poster-content mt-3">
              <h2>
                <img
                  src={gov}
                  className="me-2 head-icon"
                  alt="Government/Public Sector"
                />
                Government/Public Sector
              </h2>
              <p>
                Managed Testing Services can help the public sector by ensuring
                that software and systems work correctly and efficiently. This
                service involves a team of experts who conduct thorough testing
                to find and fix any issues, making sure everything runs
                smoothly. By using Managed Testing Services, public sector
                organizations can save time and money, reduce risks, and provide
                better services to the public. This allows them to focus on
                their core responsibilities while trusting that their technology
                is reliable and secure.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Domain;

import React, { useState } from "react";
import migrat from "../../images/Development and implementation.jpeg";
import integrat from "../../images/Agile Process Improvement.jpeg";
import develop from "../../images/Agile Transformation Strategy v1.jpeg";
import implement from "../../images/Agile Project Management.jpeg";
import session from "../../images/Agile Training and Advisory.jpeg";
import Devimplement from "./focus areas/Devimplement";
import Agileprocess from "./focus areas/Agileprocess";
import Strategy from "./focus areas/Strategy";
import Project from "./focus areas/Project";
import Training from "./focus areas/Training";

function TransformFocus() {
  const [devModal, setDevModal] = useState(false);
  const [processModal, setProcessModal] = useState(false);
  const [strategyModal, setStrategyModal] = useState(false);
  const [projectModal, setProjectModal] = useState(false);
  const [trainingModal, setTrainingModal] = useState(false);

  const openModal = () => setDevModal(true);
  const closeModal = () => setDevModal(false);

  const procesShow = () => setProcessModal(true);
  const closeprocess = () => setProcessModal(false);

  const strategyShow = () => setStrategyModal(true);
  const closestrategy = () => setStrategyModal(false);

  const projectShow = () => setProjectModal(true);
  const closeproject = () => setProjectModal(false);

  const trainingShow = () => setTrainingModal(true);
  const closetraining = () => setTrainingModal(false);
  return (
    <>
      <section className="mtsfocus">
        <div className="container">
          <h2>Focus Areas of Agile Transformation</h2>
          <div className="imgroup">
            <div className="row">
              <div className="col-12 col-lg-4 " onClick={openModal}>
                <div className="btnimg">
                  <img
                    src={migrat}
                    loading="lazy"
                    alt="Development and implementation"
                  />
                  <div className="mtsfocustxt">
                    <p>Development and implementation</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={procesShow}>
                <div className="btnimg">
                  <img
                    src={integrat}
                    loading="lazy"
                    alt="Agile Process Improvement"
                  />
                  <div className="mtsfocustxt">
                    <p>Agile Process Improvement</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={strategyShow}>
                <div className="btnimg">
                  <img
                    src={develop}
                    loading="lazy"
                    alt="Agile Transformation Strategy"
                  />
                  <div className="mtsfocustxt">
                    <p>Agile Transformation Strategy</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={projectShow}>
                <div className="btnimg">
                  <img
                    src={implement}
                    loading="lazy"
                    alt="Agile Project Management"
                  />
                  <div className="mtsfocustxt">
                    <p>Agile Project Management</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={trainingShow}>
                <div className="btnimg">
                  <img
                    src={session}
                    loading="lazy"
                    alt="Training and advisory"
                  />
                  <div className="mtsfocustxt">
                    <p>Training and advisory</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Devimplement show={devModal} handleClose={closeModal} />
      <Agileprocess show={processModal} handleClose={closeprocess} />
      <Strategy show={strategyModal} handleClose={closestrategy} />
      <Project show={projectModal} handleClose={closeproject} />
      <Training show={trainingModal} handleClose={closetraining} />
    </>
  );
}

export default TransformFocus;

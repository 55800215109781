import React from "react";
// import img from "../../images/Rpabanner (3) 1.png";
import finance from "../../images/image 118.png";
import human from "../../images/image 119.png";
import customer from "../../images/image 120.png";
import logictics from "../../images/image 121.png";
import healthcare from "../../images/image 122.png";
import operation from "../../images/image 123.png";

function Ourvalue() {
  return (
    <>
      <section className="postercontent mt-5 mb-5">
        {/* <img src={img} alt="img" className="bots" /> */}
        <div className="container">
          <div className="row w-100 bg-image">
            <h2>OUR VALUES</h2>
            <div className="poster-content mt-3">
              <h2>
                <img src={finance} className="me-2 head-icon" alt="finance" />
                Innovation
              </h2>
              <p>
                We continuously seek new ideas and embrace cutting-edge
                technologies to deliver solutions that drive progress and
                inspire change
              </p>
            </div>
            <div className="poster-content  mt-3">
              <h2>
                <img src={human} className="me-2 head-icon" alt="human" />
                Integrity
              </h2>
              <p>
                We conduct our business with honesty, transparency, and ethical
                principles, building trust with our clients, partners, and team
                members.
              </p>
            </div>
            <div className="poster-content  mt-3">
              <h2>
                <img src={customer} className="me-2 head-icon" alt="customer" />
                Excellence
              </h2>
              <p>
                We are committed to delivering high-quality solutions that
                exceed expectations and add real value to our clients'
                organizations.
              </p>
            </div>
            <div className="poster-content  mt-3">
              <h2>
                <img
                  src={logictics}
                  className="me-2 head-icon"
                  alt="logictics"
                />
                Collaboration
              </h2>
              <p>
                We believe in the power of teamwork and foster a culture of
                collaboration, both within our company and with our clients, to
                achieve shared goals.
              </p>
            </div>
            <div className="poster-content  mt-3">
              <h2>
                <img
                  src={healthcare}
                  className="me-2 head-icon"
                  alt="healthcare"
                />
                Customer-Centricity
              </h2>
              <p>
                Our customers are at the heart of everything we do. We listen,
                understand their needs, and tailor our solutions to help them
                succeed.
              </p>
            </div>
            <div className="poster-content  mt-3">
              <h2>
                <img
                  src={operation}
                  className="me-2 head-icon"
                  alt="operation"
                />
                Adaptability
              </h2>
              <p>
                In a rapidly changing digital landscape, we remain flexible and
                responsive, ready to evolve and adapt our solutions to meet the
                emerging needs of our clients.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Ourvalue;

import React, { useState } from "react";
import report from "../../images/Reports Automation-min.jpeg";
import Supportservice from "./inner/Supportservice";
import Rpagenai from "./inner/Rpagenai";
import Enablement from "./inner/Enablement";
import Botdevelopment from "./inner/Botdevelopment";
import Infasetup from "./inner/Infasetup";
import Botmigration from "./inner/Botmigration";
import Intellgen from "./inner/Intellgen";
import Coe from "./inner/Coe";
import Regulatory from "./inner/Regulatory";
import Report from "./inner/Report";
import Automation from "./inner/Automation";
import Migrat from "./inner/Migrat";
import managed from "../../images/RPA Managed Service-min.jpeg";
import support from "../../images/RPA Support and Maintenance-min.jpeg";
import gen from "../../images/RPA Process Discovery using GenAI-min.jpeg";
import enablement from "../../images/Intelligence Auomation Enablement-min.jpeg";
import bot from "../../images/RPA BOT Development-min.jpeg";
import setup from "../../images/RPA Infrastructure  Setup-min.jpeg";
import migration from "../../images/RPA BOT Migration-min.jpeg";
import intell from "../../images/Intelligent Document Processing GenAI-min.jpeg";
import coe from "../../images/COE and Governance Setup-min.png";
import regularty from "../../images/Regulatory Compliance-min.jpeg";
import customer from "../../images/Customer Service Automation-min.jpeg";

function Rpafocus() {
  const [showModal, setShowModal] = useState(false);
  const [supportModal, setSupportModal] = useState(false);
  const [rpagenModal, setRpagenModal] = useState(false);
  const [enablementModal, setEnablementModal] = useState(false);
  const [botdevelopModal, setBotdevelopModal] = useState(false);
  const [infasetupModal, setInfasetupModal] = useState(false);
  const [botmigrationModal, setBotmigrationModal] = useState(false);
  const [intellgenModal, setIntellgenModal] = useState(false);
  const [coeModal, setCoeModal] = useState(false);
  const [regulatoryModal, setRegulatoryModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [automationModal, setAutomationModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const opensupportModal = () => setSupportModal(true);
  const closesupportModal = () => setSupportModal(false);

  const openrpagenModal = () => setRpagenModal(true);
  const closeRpagenModal = () => setRpagenModal(false);

  const openenablementModal = () => setEnablementModal(true);
  const closeenablementModal = () => setEnablementModal(false);

  const openbotdevelopModal = () => setBotdevelopModal(true);
  const closebotdevelopModal = () => setBotdevelopModal(false);

  const openinfasetupModal = () => setInfasetupModal(true);
  const closeinfasetupModal = () => setInfasetupModal(false);

  const openbotmigrationModal = () => setBotmigrationModal(true);
  const closebotmigrationModal = () => setBotmigrationModal(false);

  const openintellgenModal = () => setIntellgenModal(true);
  const closeintellgenModal = () => setIntellgenModal(false);

  const opencoeModal = () => setCoeModal(true);
  const closecoeModal = () => setCoeModal(false);

  const openregulatoryModal = () => setRegulatoryModal(true);
  const closeregulatoryModal = () => setRegulatoryModal(false);

  const openreportModal = () => setReportModal(true);
  const closereportModal = () => setReportModal(false);

  const openautomationModal = () => setAutomationModal(true);
  const closeautomationModal = () => setAutomationModal(false);

  return (
    <>
      <section className="rpafocus">
        <div className="container">
          <h2>Focus Areas</h2>
          <div className="imgroup">
            <div className="row">
              <div className="col-12 col-lg-4 " onClick={openModal}>
                <div className="btnimg">
                  <img
                    src={managed}
                    loading="lazy"
                    alt="RPA Managed Services"
                  />
                  <div className="rpafocustxt">
                    <p>RPA Managed Services</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={opensupportModal}>
                <div className="btnimg">
                  <img
                    src={support}
                    loading="lazy"
                    alt="RPA Support and Maintenance Services"
                  />
                  <div className="rpafocustxt">
                    <p>RPA Support and Maintenance Services</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={openrpagenModal}>
                <div className="btnimg">
                  <img
                    src={gen}
                    loading="lazy"
                    alt="RPA Process Discovery using Gen AI"
                  />
                  <div className="rpafocustxt">
                    <p>RPA Process Discovery using Gen AI</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={openenablementModal}>
                <div className="btnimg">
                  <img
                    src={enablement}
                    loading="lazy"
                    alt="Intelligent Automation enablement"
                  />
                  <div className="rpafocustxt">
                    <p>Intelligent Automation enablement</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={openbotdevelopModal}>
                <div className="btnimg">
                  <img src={bot} loading="lazy" alt="RPA Bot Development" />
                  <div className="rpafocustxt">
                    <p>RPA Bot Development</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={openinfasetupModal}>
                <div className="btnimg">
                  <img
                    src={setup}
                    loading="lazy"
                    alt="RPA Infrastructure setup"
                  />
                  <div className="rpafocustxt">
                    <p>RPA Infrastructure setup</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={openbotmigrationModal}>
                <div className="btnimg">
                  <img src={migration} loading="lazy" alt="RPA Bot Migration" />
                  <div className="rpafocustxt">
                    <p>RPA Bot Migration</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={openintellgenModal}>
                <div className="btnimg">
                  <img
                    src={intell}
                    loading="lazy"
                    alt="Intelligent Document Processing"
                  />
                  <div className="rpafocustxt">
                    <p>Intelligent Document Processing – Gen AI</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={opencoeModal}>
                <div className="btnimg">
                  <img
                    src={coe}
                    loading="lazy"
                    alt="COE and Governance Setup"
                  />
                  <div className="rpafocustxt">
                    <p>COE and Governance Setup</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={openregulatoryModal}>
                <div className="btnimg">
                  <img
                    src={regularty}
                    loading="lazy"
                    alt="Regulatory Compliance"
                  />
                  <div className="rpafocustxt">
                    <p>Regulatory Compliance</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={openreportModal}>
                <div className="btnimg">
                  <img src={report} loading="lazy" alt="Report Automation" />
                  <div className="rpafocustxt">
                    <p>Report Automation</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={openautomationModal}>
                <div className="btnimg">
                  <img
                    src={customer}
                    loading="lazy"
                    alt="Customer Service Automation"
                  />
                  <div className="rpafocustxt">
                    <p>Customer Service Automation</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Migrat show={showModal} handleClose={closeModal} />
      <Supportservice show={supportModal} handleClose={closesupportModal} />
      <Rpagenai show={rpagenModal} handleClose={closeRpagenModal} />
      <Enablement show={enablementModal} handleClose={closeenablementModal} />
      <Botdevelopment
        show={botdevelopModal}
        handleClose={closebotdevelopModal}
      />
      <Infasetup show={infasetupModal} handleClose={closeinfasetupModal} />
      <Botmigration
        show={botmigrationModal}
        handleClose={closebotmigrationModal}
      />
      <Intellgen show={intellgenModal} handleClose={closeintellgenModal} />
      <Coe show={coeModal} handleClose={closecoeModal} />
      <Regulatory show={regulatoryModal} handleClose={closeregulatoryModal} />
      <Report show={reportModal} handleClose={closereportModal} />
      <Automation show={automationModal} handleClose={closeautomationModal} />
    </>
  );
}

export default Rpafocus;

import React from "react";
import expertise from "../../images/expertice.png";
import record from "../../images/custmizeaproach.png";
import solution from "../../images/track record.png";
import scalable from "../../images/comprensive service.png";
import cost from "../../images/focus result.png";
import compransive from "../../images/agile culture.png";
import support from "../../images/continius support.png";
import recognize from "../../images/industrey recognize.png";

function TransformChooseus() {
  return (
    <>
      <section className="mtschoose pt-5  pb-5">
        <div className="container">
          <h2>Why Choose Agile Tech Labs for Agile Transformation?</h2>
          <p>
            Agile Tech Labs is your go-to partner for Agile Transformation! We
            specialize in guiding businesses through the intricate process of
            transitioning to agile methodologies. Our team offers a wide range
            of services and strategies that are customized to meet the unique
            needs of each client. Here are several compelling reasons to choose
            Agile Tech Labs for Agile Transformation:
          </p>
          <div className="row w-100 mts-row">
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={expertise} alt="Expertise" />
                <h3>Expertise</h3>
              </div>
              <p>
                Our team of experienced agile coaches and consultants are
                experts in agile methodologies and practices. They have
                successfully guided numerous organizations through agile
                transformations.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={record} alt="Customized Approach" />
                <h3>Customized Approach</h3>
              </div>
              <p>
                We understand that every organization is unique, which is why we
                take a personalized approach to agile transformation. Our
                services are tailored to meet each client's specific needs and
                objectives.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={solution} alt="Proven Track Record" />
                <h3>Proven Track Record</h3>
              </div>
              <p>
                Agile Tech Labs has a proven track record of delivering
                successful agile transformations for clients across various
                industries. Our case studies and testimonials showcase our
                ability to drive tangible results.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={scalable} alt="Comprehensive Services" />
                <h3>Comprehensive Services</h3>
              </div>
              <p>
                From coaching and training to process improvement and leadership
                development, we offer a comprehensive range of services to
                support agile transformation. We provide end-to-end support
                throughout the entire transformation journey.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={cost} alt="Focus on Results" />
                <h3>Focus on Results</h3>
              </div>
              <p>
                We are dedicated to delivering measurable results for our
                clients. Our focus is on outcomes such as improved productivity,
                faster time-to-market, higher quality products, and increased
                customer satisfaction.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={compransive} alt="Agile Culture" />
                <h3>Agile Culture</h3>
              </div>
              <p>
                At Agile Tech Labs, we practice what we preach. We embody agile
                principles in our own culture and operations, leading by example
                and showcasing the benefits of agile practices.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={support} alt="Continuous Support" />
                <h3>Continuous Support</h3>
              </div>
              <p>
                Agile Tech Labs goes above and beyond in providing continuous
                support to clients long after the initial agile transformation
                is finished. They offer follow-up coaching, training, and
                consulting services to ensure that the transformation is not
                only sustained but also continuously improved.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={recognize} alt="Industry Recognition" />
                <h3>Industry Recognition</h3>
              </div>
              <p>
                With numerous awards and certifications, Agile Tech Labs is
                widely recognized as a leader in agile transformation. Their
                expertise and credibility in the field are unmatched, making
                them a top choice for organizations looking to make the switch
                to agile practices.
              </p>
            </div>
            <p>
              In essence, Agile Tech Labs is dedicated to guiding organizations
              through their Agile Transformation journey with enthusiasm and
              passion. By empowering them to adapt, innovate, and stay
              competitive in today's fast-paced business environment, Agile Tech
              Labs is truly making a difference.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default TransformChooseus;

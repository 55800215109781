import React, { useState } from "react";
import migrat from "../../images/Managed Testing Services-min.jpeg";
import integrat from "../../images/Automated Testing Services-min.jpeg";
import develop from "../../images/Testing Consulting services-min.jpeg";
import implement from "../../images/Test Project Augmentation-min.jpeg";
import Managetesting from "./Focus/Managetesting";
import Automatedetsting from "./Focus/Autonatedtesting";
import Cunsulting from "./Focus/Cunsulting";
import Project from "./Focus/Project";

function Mtsfocus() {
  const [showModal, setShowModal] = useState(false);
  const [automatedModal, setAutomatedModal] = useState(false);
  const [cunsultingModal, setCunsultingModal] = useState(false);
  const [projectModal, setProjectModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const testingModal = () => setAutomatedModal(true);
  const closeAutomated = () => setAutomatedModal(false);

  const cunsultModal = () => setCunsultingModal(true);
  const closecunsulting = () => setCunsultingModal(false);

  const ProjectModal = () => setProjectModal(true);
  const closeproject = () => setProjectModal(false);
  return (
    <>
      <section className="mtsfocus">
        <div className="container">
          <h2>Focus Areas</h2>
          <div className="imgroup">
            <div className="row">
              <div className="col-12 col-lg-4 " onClick={openModal}>
                {/* <Link to="/rpa"> */}
                <div className="btnimg">
                  <img src={migrat} loading="lazy" alt="migrat" />
                  <div className="mtsfocustxt">
                    <p>Managed Testing Services (a-MTS)</p>
                  </div>
                </div>
                {/* </Link> */}
              </div>
              <div className="col-12 col-lg-4" onClick={testingModal}>
                <div className="btnimg">
                  <img src={integrat} loading="lazy" alt="integrat" />
                  <div className="mtsfocustxt">
                    <p>Automated Testing Services (a-ATS)</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={cunsultModal}>
                <div className="btnimg">
                  <img src={develop} loading="lazy" alt="develop" />
                  <div className="mtsfocustxt">
                    <p>Testing Consulting services (a-TCS)</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={ProjectModal}>
                <div className="btnimg">
                  <img src={implement} loading="lazy" alt="implement" />
                  <div className="mtsfocustxt">
                    <p>Test Project Augmentation (a-TPA)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Managetesting show={showModal} handleClose={closeModal} />
      <Automatedetsting show={automatedModal} handleClose={closeAutomated} />
      <Cunsulting show={cunsultingModal} handleClose={closecunsulting} />
      <Project show={projectModal} handleClose={closeproject} />
    </>
  );
}

export default Mtsfocus;

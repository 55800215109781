import React from "react";
import img from "../../images/Rpabanner (3) 1.png";
import finance from '../../images/finance2.png'
import human from "../../images/Himanresorce1.png";
import customer from "../../images/customer2.png";
import logictics from "../../images/logistic1.png";
import healthcare from "../../images/healthcare2.png";
import operation from "../../images/itoperation1.png";

function Postercontent() {
  return (
    <>
      <section className="postercontent mt-5 mb-5">
        <img src={img} alt="img" className="bots" />
        <div className="container">
          <div className="row w-100 bg-image">
            <div className="poster-content">
              <h2>
                <img
                  src={finance}
                  className="me-2 head-icon"
                  alt="FINANCE AND ACCOUNTING"
                />
                FINANCE AND ACCOUNTING
              </h2>
              <p>
                Say goodbye to tedious manual tasks! RPA can automate mind-
                numbing processes like invoice processing, accounts
                payable/receivable, financial reporting, and compliance checks.
                With RPA by your side, financial operations will become a
                breeze, allowing your team to focus on strategic
                decision-making.
              </p>
            </div>
            <div className="poster-content">
              <h2>
                <img
                  src={human}
                  className="me-2 head-icon"
                  alt="HUMAN RESOURCES"
                />
                HUMAN RESOURCES:
              </h2>
              <p>
                Get ready to transform your HR department into a well-oiled
                machine! RPA can streamline employee onboarding/offboarding
                processes, payroll processing, benefits administration, and even
                recruitment processes. Say hello to increased productivity and
                reduced administrative burdens, giving your HR team the freedom
                to focus on what truly matters - your people.
              </p>
            </div>
            <div className="poster-content">
              <h2>
                <img
                  src={customer}
                  className="me-2 head-icon"
                  alt="CUSTOMER SERVICE"
                />
                CUSTOMER SERVICE
              </h2>
              <p>
                Brace yourself for a customer service revolution! RPA can
                automate responses to customer inquiries, order processing,
                customer data management, and overall customer experience
                enhancement. Imagine the joy of providing lightning-fast,
                accurate responses to your valued customers, leaving them
                impressed and satisfied.
              </p>
            </div>
            <div className="poster-content">
              <h2>
                <img
                  src={logictics}
                  className="me-2 head-icon"
                  alt="SUPPLY CHAIN AND LOGISTICS"
                />
                SUPPLY CHAIN AND LOGISTICS
              </h2>
              <p>
                Get ready to optimize your supply chain like never before! RPA
                can automate order processing, inventory management, shipment
                tracking, and supplier relationship management. Say goodbye to
                costly errors and delays, and hello to a well-oiled supply chain
                that keeps your business running smoothly.
              </p>
            </div>
            <div className="poster-content">
              <h2>
                <img
                  src={healthcare}
                  className="me-2 head-icon"
                  alt="healthcare"
                />
                HEALTHCARE
              </h2>
              <p>
                Prepare to witness a healthcare transformation! RPA can automate
                patient data entry, appointment scheduling, claims processing,
                and other administrative tasks, boosting efficiency and
                accuracy. With RPA as your trusted ally, healthcare
                professionals can focus on what truly matters - providing
                exceptional care to patients.
              </p>
            </div>
            <div className="poster-content">
              <h2>
                <img
                  src={operation}
                  className="me-2 head-icon"
                  alt="IT operation"
                />
                IT OPERATIONS
              </h2>
              <p>
                Brace yourself for a tech revolution! RPA can automate software
                installation, patch management, monitoring, and other routine IT
                tasks, enhancing system uptime and reducing downtime.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Postercontent;

import React from "react";
import Solution from "../About/Solution";
import Aboutus from "../About/Aboutbanner";
import Contactabout from "../About/Contactabout";
import NavbarWhite from "../Home/NavbarWhite";
import Whoweare from "../About/Whoweare";
import Aboutchoose from "../About/Aboutchoose";
import Ourvalue from "../About/Ourvalue";

const Slider = () => {
  return (
    <>
      <NavbarWhite />
      <Aboutus />
      <Solution />
      <Whoweare />
      <Ourvalue/>
      <Aboutchoose/>
      <Contactabout />
    </>
  );
};

export default Slider;
